import { type ResultOf } from 'graphql-schema';
import { Memberships, MembershipEnum, DonationFrequencyEnum } from '../../../constants';
import { filterNullOrUndefined } from '../../../utilities/array-utils';
import type { LeagueRegistrationVariant } from '../PaymentSummary';
import { DROP_IN_PRICING_FRAGMENT } from '../graphql';

type GetLineItemsFromBreakdown = ResultOf<typeof DROP_IN_PRICING_FRAGMENT> & {
  donationFrequency: DonationFrequencyEnum;
  voloPassInCart: boolean;
  monthlyCostOfVoloPassInCents?: number;
  voloPassFeesInCents?: number;
  userIsDonatingRegistrationCost?: boolean;

  /**
   * Include the cost of registration as a line item in the results. This is normally already displayed
   * elsewhere in the PaymentSummary, but this exists to support showing the line items in the right
   * rail in League Registration on web
   * @default {false}
   */
  includeRegistrationCost?: boolean;
  registrationVariant?: LeagueRegistrationVariant;
};

type LineItem = { name: string; priceInCents: number; originalPriceInCents?: number };
type Response = {
  lineItems: LineItem[];
  total: LineItem;
};

const getDonationItems = ({
  donationCents,
  donationFrequency,
  userIsDonatingRegistrationCost,
}: Pick<
  GetLineItemsFromBreakdown,
  'donationCents' | 'donationFrequency' | 'userIsDonatingRegistrationCost'
>): LineItem[] => {
  // No donation. show nothing
  if (!donationCents) return [];

  // User starts a monthly donation but also donates $1 of their registration cost. This means the user gets 2 line items
  if (
    donationCents > 100 &&
    donationFrequency === DonationFrequencyEnum.MONTHLY &&
    userIsDonatingRegistrationCost
  ) {
    return [
      {
        name: 'Donation (monthly)',
        priceInCents: donationCents - 100,
      },
      {
        name: 'Donation',
        priceInCents: 100,
      },
    ];
  }

  // Show a combined result if the $1 and the additional donation are both one-time.
  return [
    {
      name: `Donation ${donationFrequency === DonationFrequencyEnum.MONTHLY ? '(monthly)' : ''}`,
      priceInCents: donationCents,
    },
  ];
};

/**
 * A hook which accepts a pricing breakdown along with some additional fields and returns an
 * itemized list of the formatted item names and their prices in cents
 */
const getLineItemsFromBreakdown = ({
  donationFrequency,
  voloPassInCart,
  monthlyCostOfVoloPassInCents,
  voloPassFeesInCents = 0,
  creditCents,
  donationCents,
  // promoDiscountCents, // TODO: Promos are handled outside the SCL for now, but should be moved here eventually
  totalFeeCents,
  totalChargeCents,

  memberPrice,
  membershipDiscountApplied,
  originalPrice,
  userIsDonatingRegistrationCost = false,
  includeRegistrationCost = false,
  registrationVariant,
}: GetLineItemsFromBreakdown): Response => {
  // Prepaid Team Members should not have the option to add Volo Pass or donate, so no costs are
  // shown
  if (registrationVariant === 'PREPAID_TEAM_MEMBER') {
    return {
      lineItems: [
        includeRegistrationCost && {
          name: 'Registration',
          priceInCents: 0,
        },
      ].filter(filterNullOrUndefined),
      total: {
        name: 'Order Total',
        priceInCents: 0,
      },
    };
  }

  const priceMap = {
    [MembershipEnum.VOLO_PASS]: memberPrice,
  };

  let registrationCostInCents = originalPrice!;
  if (membershipDiscountApplied) {
    const enumValue = MembershipEnum[membershipDiscountApplied];
    if (enumValue === MembershipEnum.VOLO_KIDS_RECURRING) throw new Error('Unexpected input!');
    // @ts-expect-error TODO
    registrationCostInCents = priceMap[enumValue] ?? originalPrice!;
  }

  return {
    total: {
      name: 'Order Total',
      priceInCents:
        totalChargeCents +
        (voloPassInCart ? (monthlyCostOfVoloPassInCents ?? 0) + voloPassFeesInCents : 0),
    },

    lineItems: [
      includeRegistrationCost && {
        name: 'Registration',
        priceInCents: registrationCostInCents,
        ...(membershipDiscountApplied ? { originalPriceInCents: originalPrice ?? undefined } : {}),
      },
      voloPassInCart &&
        monthlyCostOfVoloPassInCents !== undefined && {
          name: Memberships[MembershipEnum.VOLO_PASS].name,
          priceInCents: monthlyCostOfVoloPassInCents,
        },
      (totalFeeCents > 0 || voloPassInCart) && {
        name: 'Service Fees',
        priceInCents: totalFeeCents + (voloPassInCart ? voloPassFeesInCents : 0),
      },
      ...getDonationItems({ donationCents, donationFrequency, userIsDonatingRegistrationCost }),
      creditCents > 0 && {
        name: 'Credits',
        priceInCents: creditCents * -1,
      },
    ].filter(filterNullOrUndefined),
  };
};

export default getLineItemsFromBreakdown;
