import { graphql, type ResultOf } from 'graphql-schema';

export const VENUE_FRAGMENT = graphql(`
  fragment VenueDetails on venues @_unmask {
    _id
    city
    timezone
    formatted_address
    shorthand_name
    neighborhood
  }
`);

export const LEAGUE_FRAGMENT = graphql(
  `
    fragment LeagueDetails on leagues @_unmask {
      _id
      start_date
      timezone
      is_volo_pass_exclusive
      is_premier
      league_photo_url
      program_type
      donations_disabled
      league_description
      display_name
      deep_link
      start_time_estimate
      end_time_estimate
      organization: organizationByOrganization {
        _id
        external_id
        is_volo_pass_active
        volo_pass_monthly_plan_id
      }
      registration: registrationByRegistration {
        _id
        registration_open_date
        registration_close_date
        min_registration_size
        max_registration_size
      }
      venue: venueByVenue {
        ...VenueDetails
      }
      registrants {
        _id
      }
      # TODO confirm competition_levels are working as expected
      competition_levels {
        _id
        competition_level
      }
    }
  `,
  [VENUE_FRAGMENT]
);

export const GET_DROP_IN_RSVPS = graphql(`
  query getDropInRSVPs($userId: uuid!) {
    registrants(
      where: { user: { _eq: $userId }, is_drop_in: { _eq: true } }
      order_by: { dropInSlotByDropInSlot: { start_time: asc } }
      limit: 100
    ) {
      _id
      dropIn: dropInSlotByDropInSlot {
        _id
        game
        team
        league
        start_time
      }
    }
  }
`);

export const GET_INDIVIDUAL_DROP_IN = graphql(`
  query getDropInSlot($dropInWhereClause: drop_in_slots_bool_exp!) {
    drop_in_slots(where: $dropInWhereClause, limit: 1) {
      _id
      game
    }
  }
`);

export const GET_GAME = graphql(
  `
    query getGame($gameWhereClause: games_bool_exp!) {
      games(where: $gameWhereClause, limit: 1) {
        _id
        start_time
        end_time
        start_time_str
        end_time_str
        field_name
        any_drop_in_capacity
        female_drop_in_capacity
        male_drop_in_capacity
        venue: venueByVenue {
          ...VenueDetails
        }
        teams {
          _id
          teamByTeam {
            _id
            name
          }
        }
        league: leagueByLeague {
          ...LeagueDetails
        }
        availableDropInSlots: drop_in_slots(
          where: {
            closed_at: { _is_null: true }
            closed_by: { _is_null: true }
            rsvp: { _is_null: true }
            hold_expiration: { _is_null: true }
          }
        ) {
          _id
          team: teamByTeam {
            _id
            name
          }
          gender
          recurring_drop_in {
            _id
            note
          }
        }
      }
    }
  `,
  [LEAGUE_FRAGMENT, VENUE_FRAGMENT]
);

export type RegistrationDataGame = ResultOf<typeof GET_GAME>['games'][number];

export const GET_LEAGUE = graphql(
  `
    query getLeague($leaguesWhereClause: leagues_bool_exp!) {
      leagues(where: $leaguesWhereClause, limit: 1) {
        ...LeagueDetails
      }
    }
  `,
  [LEAGUE_FRAGMENT]
);

export type RegistrationDataLeague = ResultOf<typeof GET_LEAGUE>['leagues'][number];

export const IS_IN_PROGRAM = graphql(`
  query isInProgram($leagueId: uuid!, $userId: uuid!) {
    registrants(where: { league: { _eq: $leagueId }, user: { _eq: $userId } }, limit: 1) {
      _id
    }
  }
`);

export const GET_PROGRAM_ROSTER = graphql(`
  query getProgramRoster($leagueId: uuid!) {
    game_rsvps(where: { gameByGame: { league: { _eq: $leagueId } }, response: { _eq: YES } }) {
      _id
      user: userByUser {
        _id
        first_name
        last_name
        full_name
        email
        phone_number
        gender
        profile_picture
        privacy_preferences {
          _id
          display_name_pickups
          share_email
          share_phone
        }
      }
    }
  }
`);

export const REGISTER_FOR_DROPIN = graphql(`
  mutation registerForDropin($input: RegisterforDropinInput!) {
    registerForDropin(input: $input) {
      registrantId
    }
  }
`);
