import { skipToken, useMutation, useSuspenseQuery } from '@apollo/client';
import moment from 'moment-timezone';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import type { VariablesOf } from 'graphql-schema';

import useActionStates from '../useActionStates';
import { omitDeepTypename } from '../../utils/pass-graphql';
import { RegistrationContext } from '../../contexts';
import { RegistrationActions } from '../../contexts/RegistrationContext/RegistrationReducer';
import { membershipPlanId } from '../../environment';
import { getToday, addDays } from '../../shared/date-helpers';
import { PlatformEnum } from '../../shared/platform-enum';
import useCurrentUserV2 from '../useCurrentUserV2';
import {
  GET_DROP_IN_RSVPS,
  GET_GAME,
  GET_INDIVIDUAL_DROP_IN,
  GET_LEAGUE,
  GET_PROGRAM_ROSTER,
  IS_IN_PROGRAM,
  REGISTER_FOR_DROPIN,
  type RegistrationDataLeague,
} from './graphql';
import hasuraClient from '../../apollo/hasuraClient';
import useVoloPassDetailsV2 from '../useVoloPassDetails/useVoloPassDetailsV2';
import { REGISTER_FOR_PROGRAM } from '../../apps/APP/Register/RegisterSections/04-Payment/graphql';
import { VOLO_PASS_MONTHLY_FEE, voloPassFindMonthlyPrice } from '../../shared/volo-pass-tiers-enum';
import { isValidUUID } from '../../utils';

const { UPDATE_REGISTRATION_STATE } = RegistrationActions;

const useRegistrationData = () => {
  const {
    dropInId,
    leagueId: dailyLeagueId,
    gameId: dropInGameId,
    invite,
  } = useParams<{ dropInId: string; leagueId: string; gameId: string; invite: string }>();

  const {
    updating: registerUpdating,
    setSuccess,
    setError,
    setUpdating,
  } = useActionStates({ withAlerts: true });

  // @ts-expect-error
  const [registerState, registerDispatch] = useContext(RegistrationContext);

  const {
    selectedSlot,
    addVpMembership,
    appliedPromo,
    donationAmount,
    dropInPriceData,
    creditAppliedAmount,
  } = registerState;

  const actualDropInSlot = selectedSlot?.value?.[0];

  const dropInPricingOptions = dropInPriceData?.dropinPricingForRegistration ?? {};
  const { currentUserPaymentCriteria } = dropInPricingOptions;
  const dropinBreakdown = addVpMembership ? 'memberBreakdown' : currentUserPaymentCriteria;
  const dropInPricingBreakdown = dropInPricingOptions?.[dropinBreakdown];

  const [league, setLeague] = useState<RegistrationDataLeague | null>(null);
  const [registrationClosed, setRegistrationClosed] = useState(false);

  const { startMembershipMutation } = useVoloPassDetailsV2();

  const [registerMutation] = useMutation(REGISTER_FOR_PROGRAM, { client: hasuraClient });
  const [dropInMutation] = useMutation(REGISTER_FOR_DROPIN, { client: hasuraClient });

  const { currentUser } = useCurrentUserV2();

  const {
    data: dropInRsvpsData,
    error: dropInRsvpError,
    refetch: dropInRsvpRefetch,
  } = useSuspenseQuery(
    GET_DROP_IN_RSVPS,
    currentUser && currentUser?._id
      ? {
          variables: {
            userId: currentUser?._id,
          },
          client: hasuraClient,
        }
      : skipToken
  );
  const { registrants } = dropInRsvpsData || {};

  type DropInWhereClauseType = VariablesOf<typeof GET_INDIVIDUAL_DROP_IN>['dropInWhereClause'];
  const dropInWhereClause: DropInWhereClauseType = {};

  if (dropInId) {
    if (isValidUUID(dropInId)) {
      dropInWhereClause._id = { _eq: dropInId };
    } else {
      dropInWhereClause.external_id = { _eq: dropInId };
    }
  }

  const { data: dropInData, error: dropInError } = useSuspenseQuery(
    GET_INDIVIDUAL_DROP_IN,
    dropInId
      ? {
          variables: { dropInWhereClause },
          client: hasuraClient,
        }
      : skipToken
  );

  type GameWhereClauseType = VariablesOf<typeof GET_GAME>['gameWhereClause'];
  const gameWhereClause: GameWhereClauseType = {};

  if (dropInGameId) {
    if (isValidUUID(dropInGameId)) {
      gameWhereClause._id = { _eq: dropInGameId };
    } else {
      gameWhereClause.external_id = { _eq: dropInGameId };
    }
  } else if (dropInData?.drop_in_slots?.[0]?.game) {
    gameWhereClause._id = { _eq: dropInData.drop_in_slots[0].game };
  }

  const { data: gameData, error: gameError } = useSuspenseQuery(
    GET_GAME,
    dropInGameId || dropInData?.drop_in_slots?.[0]?.game
      ? {
          variables: { gameWhereClause },
          client: hasuraClient,
        }
      : skipToken
  );

  const game = useMemo(() => gameData?.games?.[0], [gameData]);

  type LeaguesWhereClauseType = VariablesOf<typeof GET_LEAGUE>['leaguesWhereClause'];
  const leaguesWhereClause: LeaguesWhereClauseType = {};

  if (dailyLeagueId) {
    if (isValidUUID(dailyLeagueId)) {
      leaguesWhereClause._id = { _eq: dailyLeagueId };
    } else {
      leaguesWhereClause.external_id = { _eq: dailyLeagueId };
    }
  } else if (game?.league?._id) {
    leaguesWhereClause._id = { _eq: game.league._id };
  }

  const { data: leagueData, error: leagueError } = useSuspenseQuery(
    GET_LEAGUE,
    dailyLeagueId || game?.league?._id
      ? {
          variables: { leaguesWhereClause },
          client: hasuraClient,
        }
      : skipToken
  );

  useEffect(() => {
    const now = moment();
    if (!league && (leagueData || gameData)) {
      setLeague(() => omitDeepTypename(leagueData?.leagues?.[0] || game?.league));
    }
    const registrationOpenDate = !game?._id
      ? leagueData?.leagues?.[0]?.registration?.registration_open_date
      : '';
    const registrationCloseDate = !game?._id
      ? leagueData?.leagues?.[0]?.registration?.registration_close_date
      : '';
    // daily: registration closed if league date is before reg_open & after reg_close
    if (now.isBefore(moment(registrationOpenDate)) || now.isAfter(moment(registrationCloseDate))) {
      setRegistrationClosed(true);
    }
    // 11/2023 #396 - users can register for drop-ins until the game has ended
    if (game?._id && now.isAfter(moment(game?.end_time))) {
      setRegistrationClosed(true);
    }
    // allow for additional registrations with a group link before start date
    if (invite && league && now.isBefore(moment(league?.start_date).tz(league?.timezone))) {
      setRegistrationClosed(false);
    }
  }, [game, gameData, leagueData, league, invite]);

  const {
    data: enrollmentData,
    error: enrollmentError,
    refetch: enrollmentRefetch,
  } = useSuspenseQuery(
    IS_IN_PROGRAM,
    currentUser?._id && (league?._id || game?.league?._id)
      ? {
          variables: {
            userId: currentUser?._id,
            leagueId: league?._id ?? game?.league?._id ?? '',
          },
          client: hasuraClient,
        }
      : skipToken
  );

  const { data: programRosterData, error: programRosterError } = useSuspenseQuery(
    GET_PROGRAM_ROSTER,
    league?._id || game?.league?._id
      ? {
          variables: { leagueId: league?._id ?? game?.league?._id ?? '' },
          client: hasuraClient,
        }
      : skipToken
  );

  const programRoster = programRosterData?.game_rsvps.map(rsvp => rsvp.user);

  const isMember = !!currentUser?.has_volo_pass;
  const monthlyFee = VOLO_PASS_MONTHLY_FEE * 100;
  const monthlyPlanId = !game?._id
    ? league?.organization?.volo_pass_monthly_plan_id
    : game?.league?.organization?.volo_pass_monthly_plan_id;
  const monthlyDollarPrice = (voloPassFindMonthlyPrice(monthlyPlanId ?? '') ?? 0) * 100;
  const isActive = !game?._id
    ? league?.organization?.is_volo_pass_active
    : game?.league?.organization?.is_volo_pass_active;
  const cityName = !game?._id ? league?.venue?.city : game?.venue?.city;
  const orgId = !game?._id
    ? league?.organization?.external_id
    : game?.league?.organization?.external_id;

  const isEnrolled = !!enrollmentData?.registrants?.length;

  const isRsvpdForDropIn = registrants?.some(r => r.dropIn?.game === game?._id);

  const isEnrolledInProgram = isEnrolled || isRsvpdForDropIn;

  const today = getToday();
  const twoWeeksLater = new Intl.DateTimeFormat('en-US', {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
  }).format(addDays(today, 14));

  const isEnrolledRefetch = useCallback(async () => {
    try {
      await enrollmentRefetch();
      await dropInRsvpRefetch();
    } catch (e) {
      setError(e);
    }
  }, [dropInRsvpRefetch, enrollmentRefetch, setError]);

  const onStartVpClick = useCallback(async () => {
    try {
      registerDispatch({
        type: UPDATE_REGISTRATION_STATE,
        update: {
          addVpMembership: true,
        },
      });
    } catch (e) {
      setError(e);
    } finally {
      setSuccess('Volo Pass added to cart!');
    }
  }, [registerDispatch, setError, setSuccess]);

  const removeVpClick = useCallback(async () => {
    try {
      registerDispatch({
        type: UPDATE_REGISTRATION_STATE,
        update: {
          addVpTrial: false,
          addVpMembership: false,
          showConfirmation: false,
          showVpPopup: false,
        },
      });
    } catch (e) {
      setError(e);
    } finally {
      setSuccess('Volo Pass removed from cart');
    }
  }, [registerDispatch, setSuccess, setError]);

  const confirmDropInRsvp = useCallback(async () => {
    try {
      setUpdating(true);
      registerDispatch({
        update: {
          processingPayment: true,
          leagueId: league?._id || game?.league?._id,
        },
        type: UPDATE_REGISTRATION_STATE,
      });
      if (addVpMembership) {
        await startMembershipMutation({
          variables: {
            planId: monthlyPlanId ?? membershipPlanId ?? '',
            orgId: orgId ?? '',
          },
        });
      }
      await dropInMutation({
        variables: {
          input: {
            platform: PlatformEnum.WEB,
            dropInSlotId: actualDropInSlot?._id ?? dropInId ?? '',
            gameId: game?._id,
            donationCents: donationAmount,
            creditCents: Math.min(creditAppliedAmount, dropInPricingBreakdown?.programPriceCents),
            waiverSigned: true,
            cancellationPolicyAgreed: true,
            promoCodeStr: appliedPromo,
            teamId: actualDropInSlot?.team?._id,
            vpInCart: addVpMembership,
            isDonatingPortionOfRegistration:
              registerState?.usePaymentSummaryProps.userIsDonatingRegistrationCost,
          },
        },
      });
      registerDispatch({
        type: UPDATE_REGISTRATION_STATE,
        update: { showConfirmation: true },
      });
      setSuccess('Successfully RSVPed for drop-in.');
      await isEnrolledRefetch();
    } catch (e) {
      setError(e);
    } finally {
      registerDispatch({
        update: { processingPayment: false },
        type: UPDATE_REGISTRATION_STATE,
      });
      setUpdating(false);
    }
  }, [
    actualDropInSlot?._id,
    actualDropInSlot?.team?._id,
    addVpMembership,
    appliedPromo,
    creditAppliedAmount,
    donationAmount,
    dropInId,
    dropInMutation,
    dropInPricingBreakdown?.programPriceCents,
    game?._id,
    game?.league?._id,
    isEnrolledRefetch,
    league?._id,
    monthlyPlanId,
    orgId,
    registerDispatch,
    registerState?.usePaymentSummaryProps.userIsDonatingRegistrationCost,
    setError,
    setSuccess,
    setUpdating,
    startMembershipMutation,
  ]);

  const registerDailyError =
    leagueError ||
    gameError ||
    enrollmentError ||
    dropInError ||
    dropInRsvpError ||
    programRosterError;

  return {
    cityName,
    confirmDropInRsvp,
    game: game ?? null,
    isActive,
    isEnrolledInProgram,
    isEnrolledRefetch,
    isMember,
    isRsvpdForDropIn,
    league,
    monthlyDollarPrice,
    monthlyFee,
    onStartVpClick,
    programRoster,
    registerDailyError,
    registrationClosed,
    registerMutation,
    registerUpdating,
    removeVpClick,
    twoWeeksLater,
  };
};

export default useRegistrationData;
