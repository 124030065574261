import { InMemoryCache } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities/index.js';
const customCache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                promo_codes: offsetLimitPagination(['where', 'order_by']),
                getStripePromoList: {
                    keyArgs: false,
                    merge(existing, incoming) {
                        if (!existing)
                            return incoming;
                        return {
                            ...incoming,
                            promos: [...existing.promos, ...incoming.promos],
                        };
                    },
                },
                shift_logs: offsetLimitPagination(['where', 'order_by', 'distinct']),
                users: offsetLimitPagination(['where']),
            },
        },
        leagues: {
            fields: {
                shift_log_associated_programs: offsetLimitPagination(['where', 'order_by', 'distinct']),
            },
        },
        users: {
            fields: {
                shiftLogsByCreatedBy: offsetLimitPagination(['where', 'order_by', 'distinct']),
            },
        },
    },
});
export default customCache;
