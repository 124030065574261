import { graphql } from 'graphql-schema';
const USER = graphql(`
  query Users($where: users_bool_exp!) {
    users(where: $where) {
      _id
    }
  }
`);
export const formatMessageCenterUsersBoolExp = (args) => {
    const { rental, genders, isMarketing, voloPassOnly, reservationDates, reservationMinDate, reservationMaxDate, rentalRegistrantKinds, } = args;
    return {
        ...(genders?.length ? { gender: { _in: genders } } : {}),
        ...(voloPassOnly ? { has_volo_pass: { _eq: true } } : {}),
        ...(isMarketing
            ? {
                notification_preferences: {
                    marketing_email: { _eq: true },
                },
            }
            : {}),
        ...(rental
            ? {
                rental_registrants: {
                    rental: { _eq: rental },
                    ...(rentalRegistrantKinds?.length ? { kind: { _in: rentalRegistrantKinds } } : {}),
                    ...(reservationMinDate || reservationMaxDate || reservationDates?.length
                        ? {
                            rental_reservation: {
                                date_string: {
                                    ...(reservationDates?.length ? { _in: reservationDates } : {}),
                                    ...(reservationMinDate ? { _gte: reservationMinDate } : {}),
                                    ...(reservationMaxDate ? { _lte: reservationMaxDate } : {}),
                                },
                            },
                        }
                        : {}),
                },
            }
            : {}),
    };
};
