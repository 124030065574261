import { formatPrice } from '../../utilities/arithmetic';

export const VOLO_PASS_MONTHLY_FEE = 0.99;
export const VOLO_PASS_ANNUAL_FEE = 4.99;

// format of `planIds`
// [prod plan id, staging plan id]
export const VoloPassMonthlyPriceEnum = Object.freeze([
  {
    price: 15,
    planIds: ['price_1KbsOjCyzbeSckFn6ZLYuMjl', 'price_1KbsJGCyzbeSckFnsYISoysN'],
  },
  {
    price: 20,
    planIds: ['price_1KbsOjCyzbeSckFn7E8xImha', 'price_1KbsJLCyzbeSckFn8VzR3L7a'],
  },
  {
    price: 25,
    planIds: ['price_1KbsOjCyzbeSckFnaWjGYSKQ', 'price_1KbsJQCyzbeSckFn6jA4ER0n'],
  },
  {
    price: 29,
    planIds: ['price_1P0mCgCyzbeSckFnX9zhwsXD', 'price_1P0mDwCyzbeSckFnk1GJb1ho'],
  },
  {
    price: 30,
    planIds: ['price_1P0mAZCyzbeSckFnyypxjIeS', 'price_1P0mDjCyzbeSckFndEy7dz3g'],
  },
  {
    price: 35,
    planIds: ['price_1KbsOjCyzbeSckFnGIsnrTLk', 'price_1KbsJbCyzbeSckFnZYCgob8d'],
  },
  {
    price: 50,
    planIds: ['price_1KbsOjCyzbeSckFnPz0JgFXe', 'price_1KbsJpCyzbeSckFnX4PlxHz6'],
  },
  {
    price: 60,
    planIds: ['price_1KbsOjCyzbeSckFnLPNo510C', 'price_1KbsJvCyzbeSckFn49so6R2I'],
  },
]);

// format of `planIds`
// [prod plan id, staging plan id]
export const VoloPassAnnualPriceEnum = Object.freeze([
  {
    price: 100,
    planIds: ['price_1KbsOjCyzbeSckFnnVsLwv0R', 'price_1KbsK3CyzbeSckFn8rmzwvYq'],
  },
  {
    price: 150,
    planIds: ['price_1KbsOjCyzbeSckFneFclgfTt', 'price_1KbsKBCyzbeSckFnArvhGifr'],
  },
  {
    price: 200,
    planIds: ['price_1KbsOjCyzbeSckFnXqlXcgti', 'price_1KbsKICyzbeSckFn0B1u9xhL'],
  },
  {
    price: 250,
    planIds: ['price_1KbsOjCyzbeSckFnap86C811', 'price_1KbsKPCyzbeSckFnQL3KmDMd'],
  },
  {
    price: 290,
    planIds: ['price_1P0mDICyzbeSckFnfdvvVcwF', 'price_1P0mEPCyzbeSckFnL5sYXMmp'],
  },
  {
    price: 300,
    planIds: ['price_1P0mByCyzbeSckFnB6uqdli0', 'price_1P0mEACyzbeSckFnygWlR872'],
  },
  {
    price: 350,
    planIds: ['price_1KbsOjCyzbeSckFnhR8Hrrim', 'price_1KbsKWCyzbeSckFnBVjsjgIk'],
  },
  {
    price: 480,
    planIds: ['price_1KbsOjCyzbeSckFns5tgZmeu', 'price_1KbsKdCyzbeSckFn8KCrrU2B'],
  },
  {
    price: 575,
    planIds: ['price_1KbsOjCyzbeSckFnDm3WXS8P', 'price_1KbsKlCyzbeSckFnYihrJm00'],
  },
]);

export const VoloPassMonthlyPriceOptionsProd = Object.freeze(
  VoloPassMonthlyPriceEnum.map(({ price, planIds }) => ({
    label: formatPrice(price),
    value: planIds[0],
  }))
);

export const VoloPassAnnualPriceOptionsProd = Object.freeze(
  VoloPassAnnualPriceEnum.map(({ price, planIds }) => ({
    label: formatPrice(price),
    value: planIds[0],
  }))
);

export const voloPassFindMonthlyPrice = (planId: string) =>
  VoloPassMonthlyPriceEnum.find(m => m.planIds.includes(planId))?.price;

export const voloPassFindAnnualPrice = (planId: string) =>
  VoloPassAnnualPriceEnum.find(m => m.planIds.includes(planId))?.price;
