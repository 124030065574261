import { graphql } from 'graphql-schema';

export const GET_GROUP = graphql(`
  query getGroup($groupWhereClause: groups_bool_exp!) {
    groups(where: $groupWhereClause, limit: 1) {
      _id
      name
      is_locked
      password_hint
      prepaid: private
      registrants {
        _id
      }
      registrationByRegistration {
        _id
        league {
          _id
        }
      }
    }
  }
`);

export const GET_DROP_IN = graphql(`
  query getDropIn($dropInWhereClause: drop_in_slots_bool_exp!) {
    drop_in_slots(where: $dropInWhereClause, limit: 1) {
      _id
      game
    }
  }
`);

export const GET_GAME = graphql(`
  query getGame($gameWhereClause: games_bool_exp!) {
    games(where: $gameWhereClause, limit: 1) {
      _id
      league
      start_time
    }
  }
`);

export const GET_LEAGUE = graphql(`
  query getLeague($leaguesWhereClause: leagues_bool_exp!) {
    leagues(where: $leaguesWhereClause, limit: 1) {
      _id
      program_type
      num_weeks_estimate
      has_playoffs_estimate
      num_playoff_weeks_estimate
      start_time_estimate
      end_time_estimate
      start_date
      is_volo_pass_exclusive
      is_premier
      timezone
      weeks {
        _id
        notes
        week_tag
        week_num
      }
      featured_order
      banner_text
      name
      display_name
      format_type
      program_type
      league_photo_url
      gender
      timezone
      why_you_play_enabled
      donations_disabled
      custom_questions {
        _id
        is_mandatory_question
        question_title
        is_free_text
        dropdown_options {
          _id
          value
        }
      }
      days_of_weeks {
        _id
        name: day_of_week
      }
      divisions_activated
      # TODO Confirm divisions work as expected
      divisions {
        _id
        division: divisionByDivision {
          _id
          name
          skill_level
        }
      }
      # TODO Confirm competition levels work as expected
      competition_levels {
        _id
        competition_level
      }
      week_descriptions {
        _id
        week_num
        description
      }
      week_tags {
        _id
        week_num
        week_tag
      }
      programTag: programTagByProgramTag {
        _id
        name
      }
      neighborhood: neighborhoodByNeighborhood {
        _id
        name
      }
      sport: sportBySport {
        _id
        activityName: name
      }
      season: seasonBySeason {
        _id
        name
      }
      registration: registrationByRegistration {
        _id
        block_group_member_registrations
        block_group_captain_registrations
        block_prepaid_team_captain_registrations
        block_all_registrations
        block_individual_registrations
        limit_individual_registration
        male_capacity
        female_capacity
        max_team_size
        registration_open_date
        registration_close_date
        registration_price_phase

        early_bird_allowed
        early_bird_price
        early_bird_start_date
        early_bird_volo_pass_price
        corporate_early_bird_allowed
        corporate_early_bird_price

        regular_price
        regular_start_date
        regular_volo_pass_price
        corporate_allowed
        corporate_price

        last_chance_allowed
        last_chance_price
        last_chance_start_date
        last_chance_volo_pass_price
        corporate_last_chance_allowed
        corporate_last_chance_price
        jersey_colors {
          _id
          hex
          name
        }

        groups {
          _id
          external_id
          color_hex
          color_name
          is_locked
          name
          captain: userByCaptain {
            _id
            first_name
            last_name
            # TODO preference display name teams
          }
        }

        max_team_size
      }
      organization: organizationByOrganization {
        _id
        city
        name
        contact_email
        contact_phone_number
        is_volo_pass_active
      }
      venue: venueByVenue {
        _id
        neighborhood
        city
        shorthand_name
        venue_map_url
        formatted_address
      }
      season: seasonBySeason {
        _id
        name
      }
      waiver: waiverByWaiver {
        _id
        name
        content
      }
    }
  }
`);

export const GET_MEMBERSHIPS = graphql(`
  query getMemberships($userId: uuid!) {
    active_donation: subscriptions_aggregate(
      where: { user: { _eq: $userId }, type: { _eq: VOLO_KIDS_RECURRING }, status: { _eq: active } }
    ) {
      aggregate {
        count
      }
    }
    active_volo_pass: subscriptions_aggregate(
      where: { user: { _eq: $userId }, type: { _eq: VOLO_PASS }, status: { _eq: active } }
    ) {
      aggregate {
        count
      }
    }
  }
`);

export const PROGRAM_PRICING = graphql(`
  query programPricing($input: ProgramPricingForRegistrationInput!) {
    programPricingForRegistration(input: $input) {
      pricingBreakdown {
        programPriceCents
        promoDiscountCents
        supportFeeCents
        processingFeeCents
        processingFeePercentage
        totalFeeCents
        techFeeCents
        donationAmount
        creditAmount
        totalCents
        membershipDiscountApplied
        memberPrice
        originalPrice
        promoCode
      }
      promoCodeMessage
    }
  }
`);

const DROP_IN_PRICING_FRAGMENT = graphql(`
  fragment DropInPricingDetails on DropinPricingBreakdown {
    programPriceCents
    promoDiscountCents
    afterDiscountCents
    processingFeeCents
    flatFeeCents
    techFeeCents
    donationCents
    creditCents
    totalTransferCents
    totalFeeCents
    totalChargeCents
    feePercentage
    promoCode
    membershipDiscountApplied
    memberPrice
    originalPrice
    nonMemberPrice
    currentPhase
  }
`);

export const DROP_IN_PRICING = graphql(
  `
    query dropInPricing($input: DropinPricingForRegistrationInput!) {
      dropinPricingForRegistration(input: $input) {
        nonmemberBreakdown {
          ...DropInPricingDetails
        }
        memberBreakdown {
          ...DropInPricingDetails
        }
        currentUserPaymentCriteria
        promoCodeMessage
      }
    }
  `,
  [DROP_IN_PRICING_FRAGMENT]
);
