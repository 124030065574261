import { graphql } from 'graphql-schema';

const SEND_RENTAL_PUSH = graphql(`
  mutation SendRentalPushNotification(
    $rental: uuid!
    $message: String!
    $voloPassOnly: Boolean
    $reservationMinDate: date
    $reservationMaxDate: date
    $reservationDates: [date!]
    $genders: [GendersListItem!]
    $rentalRegistrantKinds: [RentalRegistrantKindsListItem!]
  ) {
    sendRentalPushNotification(
      rentalRegistrantKinds: $rentalRegistrantKinds
      reservationDates: $reservationDates
      reservationMaxDate: $reservationMaxDate
      reservationMinDate: $reservationMinDate
      voloPassOnly: $voloPassOnly
      genders: $genders
      message: $message
      rental: $rental
    )
  }
`);

export default SEND_RENTAL_PUSH;
