import { skipToken, useSuspenseQuery } from '@apollo/client';
import { GET_VOLO_PASS_SUBSCRIPTION_DETAILS } from './gql/GET_USER_MEMBERSHIP_DETAILS';
import {
  MembershipInterval,
  MembershipStatus,
  type MembershipIntervalType,
  type MembershipStatusType,
} from '../../constants';
import useCurrentUserV2 from '../useCurrentUserV2';

const useUserVoloPassMembershipStatus = () => {
  const { currentUser } = useCurrentUserV2();
  const {
    data: membershipData,
    error: membershipError,
    refetch: membershipRefetch,
  } = useSuspenseQuery(
    GET_VOLO_PASS_SUBSCRIPTION_DETAILS,
    currentUser && currentUser?._id
      ? {
          fetchPolicy: 'cache-and-network',
          variables: {
            userId: currentUser._id,
            type: 'VOLO_PASS',
          },
        }
      : skipToken
  );

  const { activeSubscription: activeVoloPassMembership, pastSubscription: pastVoloPassMembership } =
    membershipData || {};

  let userIsActiveVoloPassMember = false;
  if (activeVoloPassMembership && activeVoloPassMembership?.[0]) {
    userIsActiveVoloPassMember = true;
  }

  let userHasPastVoloPassMembership = false;
  if (pastVoloPassMembership && pastVoloPassMembership?.[0]) {
    userHasPastVoloPassMembership = true;
  }

  const hasMemberHistory = userIsActiveVoloPassMember || userHasPastVoloPassMembership;

  let membershipStatus: MembershipStatusType | undefined;
  let membershipStartDate = '';
  let membershipType: MembershipIntervalType | undefined;
  let membershipOrganizationId = ''; // TODO this used to have a fallback of user.home_organization

  if (userIsActiveVoloPassMember && activeVoloPassMembership && activeVoloPassMembership?.[0]) {
    const { interval, status, start_date, organization } = activeVoloPassMembership[0] || {};
    membershipStatus = MembershipStatus[status as unknown as keyof typeof MembershipStatus].label;
    membershipStartDate = start_date!;
    membershipType =
      MembershipInterval[interval as unknown as keyof typeof MembershipInterval].label;
    membershipOrganizationId = organization || '';
  } else if (
    userHasPastVoloPassMembership &&
    pastVoloPassMembership &&
    pastVoloPassMembership?.[0]
  ) {
    const { interval, status, organization, start_date } = pastVoloPassMembership[0] || {};
    membershipStatus = MembershipStatus[status as unknown as keyof typeof MembershipStatus].label;
    membershipStartDate = start_date!;
    membershipType =
      MembershipInterval[interval as unknown as keyof typeof MembershipInterval].label;
    membershipOrganizationId = organization || '';
  }

  return {
    voloPassMembershipError: membershipError,
    hasVoloPassMemberHistory: hasMemberHistory,
    voloPassMembershipStatus: membershipStatus,
    voloPassMembershipStartDate: membershipStartDate,
    voloPassMembershipType: membershipType,
    voloPassMembershipOrganizationId: membershipOrganizationId,
    voloPassMembershipRefetch: membershipRefetch,
    isVoloPassMember: !!userIsActiveVoloPassMember,
  };
};

export default useUserVoloPassMembershipStatus;
