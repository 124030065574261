import { readFragment } from 'graphql-schema';
import { MembershipEnum } from '../../../constants';
import {
  DROP_IN_PRICING_FRAGMENT,
  PRICE_BREAKDOWN_FRAGMENT,
  type DropInPricingData,
  type LeaguePricingData,
} from '../graphql';

/**
 * This function accepts the query response from either PRICING_QUERY or DROP_IN_PRICING_QUERY and
 * returns the data in a common format that can be consumed by the UI regardless of if the program
 * is a Drop-In or non-Drop-In. We should consider refactoring our pricing queries on the backend
 * to make the schema line up more consistently.
 */
const normalizePricingQueryData = (data: LeaguePricingData | DropInPricingData) => {
  // Non-drop-in data, e.g. Leagues, Pickups, Etc.
  if ('regular' in data && 'vp' in data) {
    return {
      REGULAR: readFragment(PRICE_BREAKDOWN_FRAGMENT, data.regular.pricingBreakdown),
      [MembershipEnum.VOLO_PASS]: readFragment(PRICE_BREAKDOWN_FRAGMENT, data.vp.pricingBreakdown),
    };
  }

  // Drop-in data
  if ('dropinPricingForRegistration' in data) {
    const nonmemberBreakdown = readFragment(
      DROP_IN_PRICING_FRAGMENT,
      data.dropinPricingForRegistration.nonmemberBreakdown
    );
    const memberBreakdown = readFragment(
      DROP_IN_PRICING_FRAGMENT,
      data.dropinPricingForRegistration.memberBreakdown
    );

    const originalPrice = nonmemberBreakdown?.programPriceCents ?? null;
    const memberPrice = memberBreakdown?.programPriceCents ?? null;
    return {
      REGULAR: nonmemberBreakdown
        ? {
            ...nonmemberBreakdown,
            originalPrice,
            memberPrice,
            membershipDiscountApplied: null,
          }
        : null,
      [MembershipEnum.VOLO_PASS]: memberBreakdown
        ? {
            ...memberBreakdown,
            originalPrice,
            memberPrice,
            membershipDiscountApplied: MembershipEnum.VOLO_PASS,
          }
        : null,
    };
  }

  throw new Error('No pricing could be found for this program!');
};

export default normalizePricingQueryData;
