import { type SuspenseQueryHookOptions, useSuspenseQuery } from '@apollo/client';
import { graphql, type ResultOf } from 'graphql-schema';
import { useMemo } from 'react';

const CURRENT_USER_QUERY = graphql(`
  query CurrentUser {
    currentUser {
      _id
      stripe_id
      external_id
      membership_expires
      home_organization
      volo_pass_subscription_status
      has_volo_pass
    }
  }
`);

export type CurrentUserData = ResultOf<typeof CURRENT_USER_QUERY>;

/**
 * useCurrentUser is a wrapper hook around `useQuery` that handles getting the current user
 * @example
 * const { currentUser, error } = useCurrentUser();
 */
const useCurrentUser = (options?: SuspenseQueryHookOptions) => {
  const { data, ...rest } = useSuspenseQuery(CURRENT_USER_QUERY, {
    ...options,
  });

  const currentUser = useMemo(
    () => ({
      ...data?.currentUser,
    }),
    [data?.currentUser]
  );

  return {
    ...rest,
    currentUser,
  };
};

export default useCurrentUser;
