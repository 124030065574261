import { useMutation, useQuery } from '@apollo/client';
import { Suspense, useEffect, useState, type FC, type ReactNode } from 'react';
import moment from 'moment-timezone';

import { formatMessageCenterUsersBoolExp } from '@rivallapp/shared/graphql/message-center';
import {
  Box,
  Button,
  Checkbox,
  Column,
  FormControl,
  Heading,
  Loading,
  Row,
  Text,
  TextInput,
  View,
} from '../../base-components';
import { ConfirmCancelModal, ModalAlert, RenderHTML } from '../../custom-components';
import {
  AlternateMethod,
  DateFilter,
  EmailDetails,
  Filters,
  PushNotifications,
  type EmailDetailsProps,
} from './components';
import { useMessageCenterStore } from './store';
import { ADD_EMAIL_TEMPLATES, GET_RENTAL_EMAILS, SEND_RENTAL_EMAILS } from './gql';

interface MessageCenterProps extends Pick<EmailDetailsProps, 'TextEditor' | 'organizationId'> {
  rentalId: string;
}

const fallbackConfirmationFunc = () => console.log('How did you get here?');

const MessageCenter: FC<Omit<MessageCenterProps, 'rentalId'>> = ({
  organizationId,
  TextEditor,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationModalContent, setConfirmationModalContent] = useState<ReactNode>(null);
  const [confirmFunc, setConfirmFunc] = useState<VoidFunction>(
    () => () => fallbackConfirmationFunc()
  );
  const [hideConfirm, setHideConfirm] = useState(false);

  const { filters, messageContent, subject, from, manualEmails, rentalId } = useMessageCenterStore(
    store => ({
      filters: store.filters,
      messageContent: store.messageContent,
      subject: store.subject,
      from: store.from,
      manualEmails: store.manualEmails,
      rentalId: store.rentalId,
    })
  );

  const {
    genders,
    playerTypes,
    isMarketing,
    voloPassOnly,
    selectedDates,
    startDate,
    endDate,
    dateSelectionType,
  } = filters;

  const { data: { users = [] } = {} } = useQuery(GET_RENTAL_EMAILS, {
    skip: !rentalId,
    variables: {
      where: formatMessageCenterUsersBoolExp({
        rental: rentalId,
        genders,
        isMarketing,
        voloPassOnly,
        rentalRegistrantKinds: playerTypes,
        ...(dateSelectionType === 'manual' && selectedDates?.length
          ? { reservationDates: selectedDates.map(date => moment(date).format('YYYY-MM-DD')) }
          : {}),
        ...(dateSelectionType === 'range' && startDate && endDate
          ? {
              reservationMinDate: moment(startDate, 'M/D/YY').format('YYYY-MM-DD'),
              reservationMaxDate: moment(endDate, 'M/D/YY').format('YYYY-MM-DD'),
            }
          : {}),
      }),
    },
  });

  const emailCount = users.length;
  const emailsString = users.map(({ email }) => email).join(', ');

  const [addEmailTemplate, { loading: addTemplateLoading }] = useMutation(ADD_EMAIL_TEMPLATES);
  const [sendRentalEmails, { loading: sendEmailsLoading }] = useMutation(SEND_RENTAL_EMAILS);

  const onConfirmAddTemplate = async () => {
    try {
      await addEmailTemplate({
        variables: {
          subject,
          html: messageContent,
          organization: organizationId,
        },
      });
    } catch (e: any) {
      let message = '';
      if ('message' in e) {
        message = e.message;
      } else {
        message = 'Adding a template failed. If the issue persists please contact support.';
      }
      ModalAlert.alert({
        title: 'Whoops!',
        message,
        buttons: [{ text: 'Continue', textColor: 'white.600', utilProps: { bg: 'primary.400' } }],
      });
    } finally {
      setShowConfirmation(false);
    }
  };

  const onPressAddTemplate = () => {
    setConfirmationModalContent(
      <>
        <FormControl>
          <FormControl.Label>Title of Template:</FormControl.Label>
          <TextInput
            value={subject}
            placeholder="Title"
            onChangeText={value => useMessageCenterStore.setState(() => ({ subject: value }))}
          />
        </FormControl>
        <FormControl.Label>Content:</FormControl.Label>
        <Box p="2" borderWidth="1" rounded="sm" borderColor="gray.400">
          <RenderHTML html={messageContent} />
        </Box>
      </>
    );
    setConfirmFunc(() => () => onConfirmAddTemplate());
    setHideConfirm(false);
    setShowConfirmation(true);
  };

  const onPressTestEmail = () => {
    setConfirmationModalContent(
      <>
        <FormControl isReadOnly>
          <FormControl.Label>Subject:</FormControl.Label>
          <TextInput
            value={subject}
            placeholder="Title"
            onChangeText={value => useMessageCenterStore.setState(() => ({ subject: value }))}
          />
        </FormControl>
        <FormControl.Label>Content:</FormControl.Label>
        <Box p="2" borderWidth="1" rounded="sm" borderColor="gray.400">
          <RenderHTML html={messageContent} />
        </Box>
      </>
    );
    setConfirmFunc(() => () => fallbackConfirmationFunc());
    setHideConfirm(true);
    setShowConfirmation(true);
  };

  const onConfirmSendEmail = async () => {
    try {
      await sendRentalEmails({
        variables: {
          email: {
            subject,
            from_name: from,
            rental: rentalId,
            html: messageContent,
            organization: organizationId,
            is_marketing_email: isMarketing,
            msg_membership_only: voloPassOnly,
            ...(dateSelectionType === 'range' && startDate && endDate
              ? {
                  reservation_date_minimum: moment(startDate, 'M/D/YY').format('YYYY-MM-DD'),
                  reservation_date_maximum: moment(endDate, 'M/D/YY').format('YYYY-MM-DD'),
                }
              : {}),
            ...(dateSelectionType === 'manual' && selectedDates?.length
              ? {
                  reservation_dates: {
                    data: selectedDates.map(date => ({ date: moment(date).format('YYYY-MM-DD') })),
                    on_conflict: {
                      constraint: 'email_reservation_dates_email_date_key',
                      update_columns: [],
                    },
                  },
                }
              : {}),
            genders: {
              data: genders.map(gender => ({ gender })),
              on_conflict: {
                constraint: 'email_genders_email_gender_unique',
                update_columns: [],
              },
            },
            rental_registrant_kinds: {
              data: playerTypes.map(rental_registrant_kind => ({ rental_registrant_kind })),
              on_conflict: {
                constraint: 'email_rental_registrant_kinds_unique',
                update_columns: [],
              },
            },
            manual_email_addresses: {
              data: manualEmails.map(email_address => ({ email_address })),
              on_conflict: {
                constraint: 'email_manual_email_addresses_email_address_unique',
                update_columns: [],
              },
            },
          },
        },
      });
    } catch (e: any) {
      let message = '';
      if ('message' in e) {
        message = e.message;
      } else {
        message = 'Sending email failed. If the issue persists please contact support.';
      }
      ModalAlert.alert({
        title: 'Whoops!',
        message,
        buttons: [{ text: 'Continue', textColor: 'white.600', utilProps: { bg: 'primary.400' } }],
      });
    } finally {
      setShowConfirmation(false);
    }
  };

  const onPressSendEmail = () => {
    setConfirmationModalContent(
      <>
        <Heading fontSize="md">Are you sure you want to send this email?</Heading>
        <Text my="2">
          It will be sent to <Text bold>{emailCount}</Text> people.
        </Text>
        <Text fontSize="sm" color="gray.400">
          Note: Emails sent to large # of players can take 10+ seconds to send.
        </Text>
      </>
    );
    setConfirmFunc(() => () => onConfirmSendEmail());
    setHideConfirm(false);
    setShowConfirmation(true);
  };

  if (!rentalId) {
    return <Loading />;
  }

  return (
    <View>
      <Heading color="gray.700">Compose Message</Heading>
      <Text color="gray.700">Send an email to players according to the filters selected.</Text>
      <Row my="4">
        <Column w="1/3" paddingRight="4">
          <Filters />
        </Column>
        <Column w="2/3">
          <PushNotifications />
          <DateFilter />
        </Column>
      </Row>
      <Suspense>
        <EmailDetails
          TextEditor={TextEditor}
          rentalEmailCount={emailCount}
          organizationId={organizationId}
        />
      </Suspense>
      <Row justifyContent="space-between" alignItems="center" my="4">
        <FormControl w="1/3">
          <Checkbox
            isChecked={filters.isMarketing}
            value="marketing"
            onChange={isSelected =>
              useMessageCenterStore.setState(partial => ({
                filters: {
                  ...partial.filters,
                  isMarketing: isSelected,
                },
              }))
            }
            _text={{ color: 'gray.700' }}
          >
            This message is a Marketing Email
          </Checkbox>
        </FormControl>
        <Row space="2">
          <Button
            isDisabled={showConfirmation}
            isLoading={addTemplateLoading}
            onPress={onPressAddTemplate}
            bg="gray.600"
            _hover={{ bg: 'gray.500' }}
          >
            Save Email as Template
          </Button>
          <Button onPress={onPressTestEmail} bg="gray.600" _hover={{ bg: 'gray.500' }}>
            Test Email
          </Button>
          <Button
            isDisabled={showConfirmation}
            isLoading={sendEmailsLoading}
            onPress={onPressSendEmail}
          >
            Send Email
          </Button>
        </Row>
      </Row>
      <Suspense>
        <AlternateMethod emails={emailsString} />
      </Suspense>
      <ConfirmCancelModal
        isOpen={showConfirmation}
        headerText=""
        cancelText="Close"
        onClose={() => setShowConfirmation(false)}
        onConfirm={confirmFunc}
        hideConfirm={hideConfirm}
        size="xl"
        isLoading={addTemplateLoading || sendEmailsLoading}
      >
        {confirmationModalContent}
      </ConfirmCancelModal>
    </View>
  );
};

const MessageCenterWrapper: FC<MessageCenterProps> = ({ rentalId, organizationId, TextEditor }) => {
  useEffect(() => {
    useMessageCenterStore.setState(() => ({ rentalId, organizationId }));
  }, [organizationId, rentalId]);
  return <MessageCenter organizationId={organizationId} TextEditor={TextEditor} />;
};

export default MessageCenterWrapper;
